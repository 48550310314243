import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import t from '../Components/translations.json';

const Home = () => {
    const [lng] = useState(localStorage.getItem('lng') || 'en');
    const [collection, setCollection] = useState([]);
    const [treading, setTreading] = useState([]);
    const [banner, setBanner] = useState([]);
    const [ads, setAds] = useState([]);
    const [newArrival, setNewArrival] = useState([]);
    const [slideProduct, setSlideProduct] = useState([]);
    const [brand, setBrand] = useState([]);

    useEffect(() => {
        axios.get('/slider').then(({ data }) => {
            setSlideProduct(data[0]);
        }).catch(error => console.error(error));
        axios.get('/collection').then(({ data }) => {
            setCollection(data);
        }).catch(error => console.error(error));
        axios.get('/treading').then(({ data }) => {
            setTreading(data);
        }).catch(error => console.error(error));
        axios.get('/banner').then(({ data }) => {
            setBanner(data);
        }).catch(error => console.error(error));
        axios.get('/ads').then(({ data }) => {
            setAds(data[0]);
        }).catch(error => console.error(error));
        axios.get('/new-arrival').then(({ data }) => {
            setNewArrival(data);
        }).catch(error => console.error(error));
        axios.get('/brand').then(({ data }) => {
            setBrand(data);
        }).catch(error => console.error(error));

    }, []);

    return (
        <>
            {slideProduct && (
                <section className="bg-position-top-center bg-repeat-0 pt-5 pb-5 py-md-10" style={{ backgroundImage: `url(/uploads/${slideProduct.thumbnail})` }}>
                    <div className="container pt-4 mb-3 mb-lg-0">
                        <div className="row">
                            <div className="col-lg-5 col-md-6 col-sm-8 offset-lg-1">
                                <h5 className="text-light fw-normal">
                                    {slideProduct[`product_name_${lng}`]}
                                </h5>
                                <h1 className="text-light mb-5">
                                    {slideProduct[`description_${lng}`]}
                                </h1>
                                <div className="d-flex align-items-center mb-5">
                                    <Link className="btn btn-primary me-grid-gutter" to={`/details/${slideProduct.product_id}`}>
                                        <i className="ci-cart fs-lg me-2" />{t[lng].buy}
                                    </Link>
                                    <span className="h4 mb-0 text-light fw-normal">${slideProduct.discounted_price}</span>
                                    {slideProduct.discount > 0 && <del className="fs-sm text-muted">${slideProduct.price}</del>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}

            <section className="container position-relative pt-3 pt-lg-0 pb-5 mt-lg-n10" style={{ zIndex: 10 }}>
                <div className="row">
                    <div className="col-xl-8 col-lg-9">
                        <div className="card border-0 shadow-lg">
                            <div className="card-body px-3 pt-grid-gutter pb-0">
                                <div className="row g-0 ps-1">
                                    {collection && collection.map((item, index) => (
                                        <div key={index} className="col-md-4 col-sm-6 px-1 mb-grid-gutter">
                                            <Link className="d-block text-center text-decoration-none me-1" to={`/collection/${item.id}`}>
                                                <img className="d-block rounded mb-3" src={item.collection_cover} alt={item[`collection_name_${lng}`]} />
                                                <h3 className="fs-base pt-1 mb-0">{item[`collection_name_${lng}`]}</h3>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container pt-md-3 pb-5 mb-md-3">
                <h2 className="h3 text-center">{t[lng].treading}</h2>
                <div className="row pt-4 mx-n2">
                    {treading && treading.filter(item => item.product_id !== slideProduct.product_id).map((item, index) => (
                        <div key={index} className="col-lg-3 col-md-4 col-sm-6 px-2 mb-4">
                            <div className="card product-card">
                                {item.discount > 0 && <span className="badge bg-danger badge-shadow">-{item.discount}%</span>}
                                <Link className="card-img-top d-block overflow-hidden" to={`/details/${item.product_id}`}>
                                    <img src={item.thumbnail} alt={item[`product_name_${lng}`]} />
                                </Link>
                                <div className="card-body py-2">
                                    <Link className="product-meta d-block fs-xs pb-1" to={`/category/${item.category_id}`}>{item[`category_name_${lng}`]}</Link>
                                    <h3 className="product-title fs-sm"><Link to={`/details/${item.product_id}`}>{item[`product_name_${lng}`]}</Link></h3>
                                    <div className="d-flex justify-content-between">
                                        <div className="product-price">
                                            <span className="text-accent">${item.discounted_price}</span>
                                            {item.discount > 0 && <del className="fs-sm text-muted">${item.price}</del>}
                                        </div>
                                        <div className="star-rating">
                                            <i className="star-rating-icon ci-star-filled active" />
                                            <i className="star-rating-icon ci-star-filled active" />
                                            <i className="star-rating-icon ci-star-filled active" />
                                            <i className="star-rating-icon ci-star" />
                                            <i className="star-rating-icon ci-star" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="d-sm-none" />
                        </div>
                    ))}
                </div>
                <div className="text-center pt-3">
                    <Link className="btn btn-outline-accent" to="/shop">{t[lng].more}<i className="ci-arrow-right ms-1" /></Link>
                </div>
            </section>

            <section className="container pb-4 mb-md-3">
                <div className="row">
                    <div className="col-md-8 mb-4">
                        <div className="d-sm-flex justify-content-between align-items-center bg-secondary overflow-hidden rounded-3">
                            <div className="py-4 my-2 my-md-0 py-md-5 px-4 ms-md-3 text-center text-sm-start">
                                <h3 className="mb-4">{banner && banner[0] && banner[0][`title_${lng}`]}</h3>
                                <Link className="btn btn-link btn-underline btn-sm" to={`/details/${banner && banner[0] && banner[0].product_id}`}>{t[lng].buy}<i className="ci-arrow-right fs-xs align-middle ms-1" /></Link>
                            </div>
                            <img className="d-block ms-auto" src={banner && banner[0] && banner[0].thumbnail} alt={banner && banner[0] && banner[0][`title_${lng}`]} />
                        </div>
                    </div>
                    <div className="col-md-4 mb-4">
                        <div className="d-flex flex-column h-100 justify-content-center bg-size-cover bg-position-center rounded-3" style={{ backgroundImage: `url(/uploads/${banner && banner[1] && banner[1].thumbnail})` }}>
                            <div className="py-4 my-2 px-4 text-center">
                                <div className="py-1">
                                    <h5 className="mb-2">{banner && banner[1] && banner[1][`title_${lng}`]}</h5>
                                    <Link className="btn btn-primary btn-shadow btn-sm" to={`/details/${banner && banner[1] && banner[1].product_id}`}>{t[lng].browse}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container mb-4 pb-3 pb-sm-0 mb-sm-5">
                <div className="row">
                    <div className="col-md-5">
                        {ads && (
                            <div className="d-flex flex-column h-100 overflow-hidden rounded-3" style={{ backgroundColor: '#e2e9ef' }}>
                                <div className="d-flex justify-content-between px-grid-gutter py-grid-gutter">
                                    <div>
                                        <h3 className="mb-1">{ads[`title_${lng}`]} </h3>
                                        <Link className="fs-md" to={`/details/${ads.product_id}`}>{t[lng].browse}<i className="ci-arrow-right fs-xs align-middle ms-1" /></Link>
                                    </div>
                                </div>
                                <Link className="d-none d-md-block mt-auto" to={`/details/${ads.product_id}`}>
                                    <img className="d-block w-100" src={ads.thumbnail} alt={ads[`title_${lng}`]} />
                                </Link>
                            </div>
                        )}
                    </div>

                    <div className="col-md-7 pt-4 pt-md-0">
                        <div className="row mx-n2">
                            {newArrival && newArrival.filter(item => item.product_id !== slideProduct.product_id).map((item, index) => (
                                <div key={index} className="col-lg-4 col-6 px-0 px-sm-2 mb-sm-4">
                                    <div className="card product-card card-static">
                                        <Link className="card-img-top d-block overflow-hidden" to={`/details/${item.product_id}`}>
                                            <img src={item.thumbnail} alt={item[`product_name_${lng}`]} />
                                        </Link>
                                        <div className="card-body py-2">
                                            <Link className="product-meta d-block fs-xs pb-1" to={`/category/${item.category_id}`}>{item[`category_name_${lng}`]}</Link>
                                            <h3 className="product-title fs-sm"><Link to={`/details/${item.product_id}`}>{item[`product_name_${lng}`]}</Link></h3>
                                            <div className="d-flex justify-content-between">
                                                <div className="product-price">
                                                    <span className="text-accent">${item.discounted_price}</span>
                                                    {item.discount > 0 && <del className="fs-sm text-muted">${item.price}</del>}
                                                </div>
                                                <div className="star-rating">
                                                    <i className="star-rating-icon ci-star-filled active" />
                                                    <i className="star-rating-icon ci-star-filled active" />
                                                    <i className="star-rating-icon ci-star-filled active" />
                                                    <i className="star-rating-icon ci-star" />
                                                    <i className="star-rating-icon ci-star" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            </section>

            <section className="container py-lg-4 mb-4">
                <h2 className="h3 text-center pb-4">{t[lng].shop_by_brand}</h2>
                <div className="row">
                    {brand && brand.map((item, index) => (
                        <div key={index} className="col-md-3 col-sm-4 col-6">
                            <Link className="d-block bg-white shadow-sm rounded-3 py-3 py-sm-4 mb-grid-gutter" to={`/brand/${item.id}`}>
                                <img className="d-block mx-auto" src={item.brand_logo} style={{ width: 150 }} alt={item.brand_name} />
                            </Link>
                        </div>
                    ))}
                </div>
            </section>

        </>
    );
}

export default Home;