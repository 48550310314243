import { Link, useNavigate } from "react-router-dom";

const ErrorPage = () => {
    const Navigate = useNavigate();
    const handleSearch = (e) => {
        const searchValue = e.target.value;
        return Navigate(`/result/${searchValue}`);
    }

    return (
        <div className="container py-5 mb-lg-3">
            <div className="row justify-content-center pt-lg-4 text-center">
                <div className="col-lg-5 col-md-7 col-sm-9">
                    <h1 className="display-404 py-lg-3">404</h1>
                    <h2 className="h3 mb-4">We can't seem to find the page you are looking for.</h2>
                    <p className="fs-md mb-4">
                        <u>Here are some helpful links instead:</u>
                    </p>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-10">
                    <div className="row">
                        <div className="col-sm-4 mb-3">
                            <Link className="card h-100 border-0 shadow-sm" to="/">
                                <div className="card-body">
                                    <div className="d-flex align-items-center"><i className="ci-home text-primary h4 mb-0" />
                                        <div className="ps-3">
                                            <h5 className="fs-sm mb-0">Homepage</h5><span className="text-muted fs-ms">Return to homepage</span>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-4 mb-4">
                            <div className="card h-100 border-0 shadow-sm">
                                <div className="card-body">
                                    <div className="d-flex align-items-center"><i className="ci-search text-success h4 mb-0" />
                                        <div className="ps-3">
                                            <input className="form-control rounded-end pe-5" type="text" placeholder="Or Search for ..." name="search" onKeyDown={(e) => { e.key === 'Enter' && handleSearch(e); }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4 mb-3">
                            <Link className="card h-100 border-0 shadow-sm" to="/contact">
                                <div className="card-body">
                                    <div className="d-flex align-items-center"><i className="ci-help text-info h4 mb-0" />
                                        <div className="ps-3">
                                            <h5 className="fs-sm mb-0">Help &amp; Support</h5><span className="text-muted fs-ms">Visit our help center</span>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default ErrorPage;