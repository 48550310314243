import Footer from "./Components/Footer";
import Header from "./Components/Header";

function App({ children }) {
  return (
    <>
      <main className="page-wrapper">
        <Header />
        {children}
      </main>
      <Footer />
    </>

  );
}

export default App;
