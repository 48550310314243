import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import t from '../Components/translations.json';

const Details = () => {
    const [lng] = useState(localStorage.getItem('lng') || 'en');
    const { id } = useParams();
    const [product, setProduct] = useState({});
    const [productImages, setProductImages] = useState([]);
    const [relatedProducts, setRelatedProducts] = useState([]);

    useEffect(() => {
        axios.get(`/product/${id}`).then((res) => {
            setProduct(res.data[0]);
            axios.get(`/product-images/${res.data[0].product_id}`).then((img) => {
                setProductImages(img.data);
            });
            axios.get(`/related-products/${res.data[0].category_id}`).then((rel) => {
                setRelatedProducts(rel.data);
            });
        });

    }, [id]);

    const whatsAppMessage = encodeURIComponent(`${t[lng].wtsmsg}: ${product[`product_name_${lng}`]}, $${product.discounted_price},https://doskitrade.com/details/${id}`);

    const [info, setInfo] = useState([]);
    useEffect(() => {
        axios.get('/info').then((res) => {
            setInfo(res.data[0]);
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    return (
        <>
            <div className="page-title-overlap bg-dark pt-4">
                <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
                    <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                                <li className="breadcrumb-item"><Link className="text-nowrap" to="/"><i className="ci-home" />{t[lng].home}</Link></li>
                                <li className="breadcrumb-item text-nowrap active" aria-current="page">{t[lng].details}</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="order-lg-1 pe-lg-4 text-center text-lg-start">
                        <h1 className="h3 text-light mb-0">{product && product[`product_name_${lng}`]}</h1>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="bg-light shadow-lg rounded-3 px-4 py-3 mb-5">
                    <div className="px-lg-3">
                        <div className="row">
                            <div className="col-lg-7 pe-lg-0 pt-lg-4">
                                <div className="product-gallery">
                                    <div className="product-gallery-preview order-sm-2">
                                        {productImages.map((img, index) => (
                                            <div key={index} className={`product-gallery-preview-item ${index === 0 ? 'active' : ''}`} id={`img-${index}`}>
                                                <img className="image-zoom" src={`../uploads/${img.image}`} data-zoom={`../uploads/${img.image}`} alt={`image-${index}`} />
                                                <div className="image-zoom-pane" />
                                            </div>
                                        ))}
                                    </div>
                                    <div className="product-gallery-thumblist order-sm-1">
                                        {productImages.map((img, index) => (
                                            <span type='button' key={index} className={`product-gallery-thumblist-item ${index === 0 ? 'active' : ''}`} onClick={(e) => {
                                                document.querySelectorAll('.product-gallery-preview-item').forEach((item) => {
                                                    item.classList.remove('active');
                                                });
                                                document.getElementById(`img-${index}`).classList.add('active');
                                                e.preventDefault();
                                            }} >
                                                <img src={`../uploads/${img.image}`} alt={`image-${index}`} />
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 pt-4 pt-lg-0">
                                <div className="product-details ms-auto pb-3">
                                    <div className="d-flex justify-content-between align-items-center mb-2">
                                        <div className="accordion-item">
                                            <div className="accordion-body">
                                                <h2 className="h3 pb-2">{product && product[`product_name_${lng}`]}</h2>
                                                <div className="d-flex justify-content-between align-items-center mb-2">
                                                    <a href="/reviews" data-scroll>
                                                        <div className="star-rating">
                                                            <i className="star-rating-icon ci-star-filled active" />
                                                            <i className="star-rating-icon ci-star-filled active" />
                                                            <i className="star-rating-icon ci-star-filled active" />
                                                            <i className="star-rating-icon ci-star-filled active" />
                                                            <i className="star-rating-icon ci-star" />
                                                        </div>
                                                    </a>
                                                </div>
                                                <span className="h3 fw-normal text-accent me-1 mb-3">${product && product.discounted_price}</span>
                                                {product && product.discount > 0 && <del className="fs-sm text-muted">${product && product.price}</del>}
                                                <h6 className="fs-base mb-3">{product && product[`category_name_${lng}`]}</h6>
                                                <p className="fs-sm text-muted pb-2 mb-3">{product && product[`description_${lng}`]}</p>

                                                <a href={`https://wa.me/${info.whatsapp}?text=${whatsAppMessage}`} target="_blank" rel="noopener noreferrer" className="btn btn-primary">
                                                    <i className="ci-cart" /> {t[lng].order}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container py-5 my-md-3">
                <h2 className="h3 text-center pb-4">{t[lng].you_may_also_like}</h2>
                <div className={`row ${lng === 'ar' ? 'rtl' : ''}`}>
                    {relatedProducts && relatedProducts.map((item, index) => (
                        <div key={index} className="col-md-4 col-lg-3">
                            <div className="card product-card card-static">
                                <Link className="card-img-top d-block overflow-hidden" to={`/details/${item.product_id}`}>
                                    <img src={`../uploads/${item.thumbnail}`} alt={item[`product_name_${lng}`]} />
                                </Link>
                                <div className="card-body py-2">
                                    <Link className="product-meta d-block fs-xs pb-1" to={`/category/${item.category_id}`}>{item[`category_name_${lng}`]}</Link>
                                    <h3 className="product-title fs-sm"><Link to={`/details/${item.product_id}`}>{item[`product_name_${lng}`]}</Link></h3>
                                    <div className="d-flex justify-content-between">
                                        <div className="product-price">
                                            <span className="text-accent">${item.discounted_price}</span>
                                            {item.discount > 0 && <del className="fs-sm text-muted">${item.price}</del>}
                                        </div>
                                        <div className="star-rating">
                                            <i className="star-rating-icon ci-star-filled active" />
                                            <i className="star-rating-icon ci-star-filled active" />
                                            <i className="star-rating-icon ci-star-filled active" />
                                            <i className="star-rating-icon ci-star" />
                                            <i className="star-rating-icon ci-star" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div >
        </>
    );
}

export default Details;