import React from 'react';
import { createBrowserRouter } from "react-router-dom";
import ErrorPage from './Error';
import App from '../App';
import Home from '../Pages/Home';
import Shop from '../Pages/Shop';
import Details from '../Pages/Details';
import Brand from '../Pages/Brand';
import Category from '../Pages/Category';
import Search from '../Pages/Search';
import Collection from '../Pages/Collection';
import NewArrival from '../Pages/newArrival';
import Discount from '../Pages/Discount';

const Routes = createBrowserRouter([
    {
        path: "/",
        element: <App><Home /></App>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/shop",
        element: <App><Shop /></App>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/new",
        element: <App><NewArrival /></App>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/details/:id",
        element: <App><Details /></App>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/brand/:id",
        element: <App><Brand /></App>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/discount",
        element: <App><Discount /></App>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/collection/:id",
        element: <App><Collection /></App>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/category/:id",
        element: <App><Category /></App>,
        errorElement: <ErrorPage />,
    },
    {
        path: "/result/:value",
        element: <App><Search /></App>,
        errorElement: <ErrorPage />,
    }
]);

export default Routes;