import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Pagination from "../Components/Pagination";
import t from '../Components/translations.json';
import Filters from "../Components/Filters";

const Shop = () => {
    const [lng] = useState(localStorage.getItem('lng') || 'en');
    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const fetchData = async (page) => {
        const { data } = await axios.get(`/shop-product`, { params: { page: page } });
        setProducts(data.data);
        setTotalPages(Math.ceil(data.totalPages / 10));
    }

    useEffect(() => {
        fetchData(currentPage);
    }, [currentPage]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <>
            <div>
                <div className="bg-dark pt-4 pb-5">
                    <div className="container pt-2 pb-3 pt-lg-3 pb-lg-4">
                        <div className="d-lg-flex justify-content-between pb-3">
                            <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                                        <li className="breadcrumb-item"><Link className="text-nowrap" to="/"><i className="ci-home" />{t[lng].home}</Link></li>
                                        <li className="breadcrumb-item text-nowrap active" aria-current="page">{t[lng].shop}</li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="order-lg-1 pe-lg-4 text-center text-lg-start">
                                <h1 className="h3 text-light mb-0">{t[lng].for_you_products}</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container pb-5 mb-2 mb-md-4">
                    <div className="bg-light shadow-lg rounded-3 p-4 mt-n5 mb-4">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="dropdown me-2">
                                <a className="btn btn-outline-secondary dropdown-toggle" href="#shop-filters" data-bs-toggle="collapse">
                                    <i className="ci-filter me-2" />{t[lng].filter}
                                </a>
                            </div>
                            <div className="d-flex d-sm-flex">
                                <a className="btn btn-icon nav-link-style bg-primary text-white" href={`/shop`}>
                                    <i className="ci-compare me-2" />{t[lng].refreash}
                                </a>
                            </div>
                        </div>
                        <div className="collapse" id="shop-filters">
                            <Filters />
                        </div>
                    </div>
                    <div className={`row pt-3 mx-n2 ${lng === 'ar' ? 'rtl' : ''}`}>
                        {products && products.map((item, index) => (
                            <div key={index} className="col-lg-3 col-md-4 col-sm-6 px-2 mb-4">
                                <div className="card product-card">
                                    {item.discount > 0 && <span className="badge bg-danger badge-shadow">-{item.discount}%</span>}
                                    <Link className="card-img-top d-block overflow-hidden" to={`/details/${item.product_id}`}>
                                        <img src={`../uploads/${item.thumbnail}`} alt={item[`product_name_${lng}`]} />
                                    </Link>
                                    <div className="card-body py-2">
                                        <Link className="product-meta d-block fs-xs pb-1" to={`/category/${item.category_id}`}>{item[`category_name_${lng}`]}</Link>
                                        <h3 className="product-title fs-sm"><Link to={`/details/${item.product_id}`}>{item[`product_name_${lng}`]}</Link></h3>
                                        <div className="d-flex justify-content-between">
                                            <div className="product-price">
                                                <span className="text-accent">${item.discounted_price}</span>
                                                {item.discount > 0 && <del className="fs-sm text-muted">${item.price}</del>}
                                            </div>
                                            <div className="star-rating">
                                                <i className="star-rating-icon ci-star-filled active" />
                                                <i className="star-rating-icon ci-star-filled active" />
                                                <i className="star-rating-icon ci-star-filled active" />
                                                <i className="star-rating-icon ci-star" />
                                                <i className="star-rating-icon ci-star" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className="d-sm-none" />
                            </div>
                        ))}
                    </div>
                    <hr className="my-3" />
                    <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                </div>
            </div>
        </>
    );
}

export default Shop;