import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import t from './translations.json';
import axios from 'axios';

const Header = () => {
    const [lng, setlng] = useState(localStorage.getItem('lng') || 'en');
    const toggleLanguage = () => {
        setlng(lng === 'en' ? 'ar' : 'en');
        localStorage.setItem('lng', lng === 'en' ? 'ar' : 'en');
        window.location.reload();
    };

    const Navigate = useNavigate();
    const handleSearch = (e) => {
        const searchValue = e.target.value;
        return Navigate(`/result/${searchValue}`);
    }

    const [info, setInfo] = useState([]);
    useEffect(() => {
        axios.get('/info').then((res) => {
            setInfo(res.data[0]);
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    return (
        <header className="shadow-sm">
            <div className="topbar topbar-dark bg-dark mb-2">
                <div className="container">
                    <div className="topbar-text dropdown d-md-none">
                        <a className="topbar-link dropdown-toggle" href="#0" data-bs-toggle="dropdown">{t[lng].support}</a>
                        <ul className="dropdown-menu">
                            <li>
                                <a className="dropdown-item" href={`tel:${info.phone_no}`}><i className="ci-support text-muted me-2" />{info.phone_no}</a>
                            </li>
                        </ul>
                    </div>
                    <div className="topbar-text text-nowrap d-none d-md-inline-block">
                        <i className="ci-support" />
                        <span className="text-muted me-1">{t[lng].support}</span>
                        <a className="topbar-link" href={`tel:${info.phone_no}`}>{info.phone_no}</a>
                    </div>
                    <div className="ms-3 text-nowrap">
                        <div className="topbar-text dropdown disable-autohide">
                            <i className="ci-globe" />
                            <span type="button" className="topbar-link dropdown-toggle" data-bs-toggle="dropdown">{lng === 'en' ? 'English' : 'العربية'}</span>
                            <ul className="dropdown-menu dropdown-menu-end">
                                <li>
                                    <span type="button" className="dropdown-item pb-1" onClick={toggleLanguage}>{lng === 'en' ? 'العربية' : 'English'}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="navbar-sticky bg-light">
                <div className="navbar navbar-expand-lg navbar-light">
                    <div className="container">
                        <Link className="navbar-brand d-none d-sm-block flex-shrink-0" to="/">
                            <span className="text-dark">Doski</span><span className="text-primary">Trade</span>
                        </Link>
                        <Link className="navbar-brand d-sm-none flex-shrink-0 me-2" to="/">
                            <span className="text-dark">Doski</span><span className="text-primary">Trade</span>
                        </Link>
                        <div className="input-group d-none d-lg-flex mx-4">

                            <input className="form-control rounded-end pe-5" type="text" placeholder={t[lng].search_placeholder} name="search" onKeyDown={(e) => { e.key === 'Enter' && handleSearch(e); }} />
                            <i className="ci-search position-absolute top-50 end-0 translate-middle-y text-muted fs-base me-3" />
                        </div>
                        <div className="navbar-toolbar d-flex flex-shrink-0 align-items-center">
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                                <span className="navbar-toggler-icon" />
                            </button>
                        </div>
                        <div className={`collapse navbar-collapse ${lng === 'ar' ? 'rtl' : ''}`} id="navbarCollapse" >
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/">{t[lng].home}</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/shop">{t[lng].shop}</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/new">{t[lng].new}</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/discount">{t[lng].discount}</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>

    );
}

export default Header;