import React, { useState } from 'react';
import t from './translations.json';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const [lng] = useState(localStorage.getItem('lng') || 'en');
    const MAX_PAGES = 3;
    let startPage = Math.max(currentPage - Math.floor(MAX_PAGES / 2), 1);
    let endPage = Math.min(startPage + MAX_PAGES - 1, totalPages);
    startPage = Math.max(endPage - MAX_PAGES + 1, 1);
    const pageNumbers = Array.from({ length: (endPage - startPage) + 1 }, (_, i) => startPage + i);
    return (
        <nav className={`d-flex justify-content-between pt-2 ${lng === 'ar' ? 'rtl' : ''}`} aria-label="Page navigation">
            <ul className="pagination">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => onPageChange(currentPage - 1)}><i className="ci-arrow-left me-2" /> {t[lng].prev}</button>
                </li>
            </ul>
            <ul className="pagination">
                {pageNumbers.map(pageNumber => (
                    <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
                        <button className="page-link" onClick={() => onPageChange(pageNumber)}>{pageNumber}</button>
                    </li>
                ))}
            </ul>
            <ul className="pagination">
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => onPageChange(currentPage + 1)}><i className="ci-arrow-right ms-2" /> {t[lng].next}</button>
                </li>
            </ul>
        </nav>
    );
};

export default Pagination;
