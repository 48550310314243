import axios from "axios";
import { useEffect, useState } from "react";
import t from "./translations.json";
import { Link } from "react-router-dom";

const Filters = () => {
    const [lng] = useState(localStorage.getItem('lng') || 'en');
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [collections, setCollections] = useState([]);

    useEffect(() => {
        axios.get(`/filter-categories`).then((res) => {
            setCategories(res.data);
        });
        axios.get(`/filter-brands`).then((res) => {
            setBrands(res.data);
        });
        axios.get(`/filter-collections`).then((res) => {
            setCollections(res.data);
        });
    }, []);
    return (
        <div className={`row pt-4 ${lng === 'ar' ? 'rtl' : ''}`}>
            <div className="col-lg-4 col-sm-6">
                <div className="card mb-grid-gutter">
                    <div className="card-body px-4">
                        <div className="widget widget-categories">
                            <h3 className="widget-title">{t[lng].categories}</h3>
                            <div className="accordion mt-n1" id="shop-categories">
                                <div className="accordion-item">
                                    {categories && categories.map((item, index) => (
                                        <p key={index} className="accordion-header"><Link className="text-dark text-muted" to={`/category/${item.id}`}> {item[`category_name_${lng}`]}</Link></p>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-sm-6">
                <div className="card mb-grid-gutter">
                    <div className="card-body px-4">
                        <div className="widget widget-categories">
                            <h3 className="widget-title">Brands</h3>
                            <div className="accordion mt-n1" id="shop-categories">
                                <div className="accordion-item">
                                    {brands && brands.map((item, index) => (
                                        <p key={index} className="accordion-header"><Link className="text-dark text-muted" to={`/brand/${item.id}`}>{item.brand_name}</Link></p>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-sm-6">
                <div className="card mb-grid-gutter">
                    <div className="card-body px-4">
                        <div className="widget widget-categories">
                            <h3 className="widget-title">{t[lng].collections}</h3>
                            <div className="accordion mt-n1" id="shop-categories">
                                <div className="accordion-item">
                                    {collections && collections.map((item, index) => (
                                        <p key={index} className="accordion-header"><Link className="text-dark text-muted" to={`/collection/${item.id}`}> {item[`collection_name_${lng}`]}</Link></p>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Filters;